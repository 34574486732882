@import '~@amzn/awsui-components-react/sass/constants';
@import '~@amzn/awsui-components-react/sass/mixins';

.awsui-polaris-dark-mode {
  .awsui-util-label {
    color: var(--awsui-color-grey-450);
  }
  .awsui-table-header, .awsui-cards-header, .awsui-form-header {
    color: var(--awsui-color-text-header);
  }
}
