@import '~@amzn/awsui-components-react/sass/constants';
@import 'app-layout';
@import 'header';

.custom-header {
  display: flex;
  align-items: flex-end;
}

.custom-info-link {
  margin-left: 1rem;
  font-size: 1.2rem;
  line-height: 2rem;
  font-weight: bold;
}

.custom-tiles {
  box-sizing: border-box;
  border-radius: 2px;
  height: 100%;
  padding: 1rem 1.5rem;
  border: 1px solid $awsui-grey-400;
  border: 1px solid var(--awsui-color-border-control-default);
}

.custom-tiles-selected {
  border: 1px solid $awsui-blue-500;
  border: 1px solid var(--awsui-color-border-control-focused);
  background: $awsui-blue-100;
  background: var(--awsui-color-background-control-selected);
}

.date-time-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.awsui-polaris-dark-mode {
  .awsui-form-title {
    color: var(--awsui-color-grey-200);
  }
}
