/**
 * Do not edit directly
 */

$internal-awsui-color-grey-100: #fafafa !default;
$internal-awsui-color-grey-150: #f2f3f3 !default;
$internal-awsui-color-grey-200: #eaeded !default;
$internal-awsui-color-grey-300: #d5dbdb !default;
$internal-awsui-color-grey-400: #aab7b8 !default;
$internal-awsui-color-grey-450: #95a5a6 !default;
$internal-awsui-color-grey-500: #879596 !default;
$internal-awsui-color-grey-550: #687078 !default;
$internal-awsui-color-grey-600: #545b64 !default;
$internal-awsui-color-grey-650: #414750 !default;
$internal-awsui-color-grey-700: #2a2e33 !default;
$internal-awsui-color-grey-750: #21252c !default;
$internal-awsui-color-grey-800: #1a2029 !default;
$internal-awsui-color-grey-900: #16191f !default;
$internal-awsui-color-grey-950: #222222 !default;
$internal-awsui-color-grey-transparent-heavy: rgba(0, 28, 36, 0.5) !default;
$internal-awsui-color-grey-transparent: rgba(0, 28, 36, 0.3) !default;
$internal-awsui-color-grey-transparent-light: rgba(0, 28, 36, 0.15) !default;
$internal-awsui-color-grey-opaque-25: rgba(255, 255, 255, 0.25) !default;
$internal-awsui-color-grey-opaque-40: rgba(0, 0, 0, 0.4) !default;
$internal-awsui-color-grey-opaque-50: rgba(0, 0, 0, 0.5) !default;
$internal-awsui-color-grey-opaque-70: rgba(255, 255, 255, 0.7) !default;
$internal-awsui-color-grey-opaque-80: rgba(22, 25, 31, 0.8) !default;
$internal-awsui-color-grey-opaque-90: rgba(242, 243, 243, 0.9) !default;
$internal-awsui-color-orange-100: #fef6f0 !default;
$internal-awsui-color-orange-300: #f7bf9c !default;
$internal-awsui-color-orange-500: #ec7211 !default;
$internal-awsui-color-orange-600: #eb5f07 !default;
$internal-awsui-color-orange-700: #dd6b10 !default;
$internal-awsui-color-blue-100: #f1faff !default;
$internal-awsui-color-blue-300: #99cbe4 !default;
$internal-awsui-color-blue-400: #44b9d6 !default;
$internal-awsui-color-blue-500: #00a1c9 !default;
$internal-awsui-color-blue-600: #0073bb !default;
$internal-awsui-color-blue-700: #0a4a74 !default;
$internal-awsui-color-blue-900: #12293b !default;
$internal-awsui-color-blue-opaque: rgba(51, 136, 221, 0.5) !default;
$internal-awsui-color-green-100: #f2f8f0 !default;
$internal-awsui-color-green-300: #a5d099 !default;
$internal-awsui-color-green-500: #6aaf35 !default;
$internal-awsui-color-green-600: #1d8102 !default;
$internal-awsui-color-green-700: #1a520f !default;
$internal-awsui-color-green-900: #172211 !default;
$internal-awsui-color-red-100: #fdf3f1 !default;
$internal-awsui-color-red-300: #f2ada0 !default;
$internal-awsui-color-red-500: #ff5d64 !default;
$internal-awsui-color-red-600: #d13212 !default;
$internal-awsui-color-red-700: #7c2718 !default;
$internal-awsui-color-red-900: #270a11 !default;
$internal-awsui-color-black: #000000 !default;
$internal-awsui-color-white: #ffffff !default;
$internal-awsui-color-aws-squid-ink: #232f3e !default;
$internal-awsui-color-amazon-orange: #ff9900 !default;
$internal-awsui-color-transparent: rgba(0, 0, 0, 0) !default;
$internal-awsui-color-text-header: #16191f !default;
$internal-awsui-color-text-base: #16191f !default;
$internal-awsui-color-text-secondary: #545b64 !default;
$internal-awsui-color-text-pagination-active: #16191f !default;
$internal-awsui-color-text-section: #879596 !default;
$internal-awsui-color-text-description: #687078 !default;
$internal-awsui-color-text-inverted: #ffffff !default;
$internal-awsui-color-text-disabled: #aab7b8 !default;
$internal-awsui-color-text-hover: #16191f !default;
$internal-awsui-color-text-active: #16191f !default;
$internal-awsui-color-text-primary: #eb5f07 !default;
$internal-awsui-color-text-nav-selected: #ec7211 !default;
$internal-awsui-color-text-nav-title: #545b64 !default;
$internal-awsui-color-text-link-default: #0073bb !default;
$internal-awsui-color-text-link-hover: #00a1c9 !default;
$internal-awsui-color-text-inverted-link-default: #00a1c9 !default;
$internal-awsui-color-text-inverted-link-hover: #00a1c9 !default;
$internal-awsui-color-text-input: #000000 !default;
$internal-awsui-color-text-flash: #ffffff !default;
$internal-awsui-color-text-status-error: #d13212 !default;
$internal-awsui-color-text-status-warning: #d13212 !default;
$internal-awsui-color-text-status-success: #1d8102 !default;
$internal-awsui-color-text-status-info: #0073bb !default;
$internal-awsui-color-text-status-inactive: #687078 !default;
$internal-awsui-color-text-status-pending: #687078 !default;
$internal-awsui-color-text-breadcrumb: #687078 !default;
$internal-awsui-color-text-control-label: #16191f !default;
$internal-awsui-color-text-control-default: #687078 !default;
$internal-awsui-color-text-control-small: #687078 !default;
$internal-awsui-color-text-control-disabled: #d5dbdb !default;
$internal-awsui-color-text-form-disabled: #879596 !default;
$internal-awsui-color-text-icon-default: #879596 !default;
$internal-awsui-color-text-icon-sorting: #879596 !default;
$internal-awsui-color-text-icon-disabled: #aab7b8 !default;
$internal-awsui-color-text-icon-inverted: #ffffff !default;
$internal-awsui-color-text-icon-subtle: #687078 !default;
$internal-awsui-color-text-icon-warning: #eb5f07 !default;
$internal-awsui-color-text-spinner-disabled: #879596 !default;
$internal-awsui-color-text-spinner-inverted: #ffffff !default;
$internal-awsui-color-text-label: #687078 !default;
$internal-awsui-color-text-placeholder: #aab7b8 !default;
$internal-awsui-color-text-column-header: #545b64 !default;
$internal-awsui-color-text-tooltip: #d5dbdb !default;
$internal-awsui-color-text-checkbox-default: #ffffff !default;
$internal-awsui-color-text-checkbox-disabled: #ffffff !default;
$internal-awsui-color-text-button-primary-default: #ffffff !default;
$internal-awsui-color-text-button-primary-hover: #ffffff !default;
$internal-awsui-color-text-button-primary-active: #ffffff !default;
$internal-awsui-color-text-button-disabled: #aab7b8 !default;
$internal-awsui-color-text-dropdown-item-default: #16191f !default;
$internal-awsui-color-text-dropdown-item-highlighted: #16191f !default;
$internal-awsui-color-text-dropdown-item-secondary: #687078 !default;
$internal-awsui-color-text-dropdown-item-disabled: #aab7b8 !default;
$internal-awsui-color-text-dropdown-item-filter-match-highlight: #0073bb !default;
$internal-awsui-color-text-dropdown-header-default: #545b64 !default;
$internal-awsui-color-text-dropdown-footer: #687078 !default;
$internal-awsui-color-text-badge: #ffffff !default;
$internal-awsui-color-background-layout: #f2f3f3 !default;
$internal-awsui-color-background-inverted: #16191f !default;
$internal-awsui-color-background-state-selected: #f1faff !default;
$internal-awsui-color-background-state-active: #eaeded !default;
$internal-awsui-color-background-link-hover: #fafafa !default;
$internal-awsui-color-background-link-active: #eaeded !default;
$internal-awsui-color-background-modal-overlay: rgba(242, 243, 243, 0.9) !default;
$internal-awsui-color-background-checkbox: #ffffff !default;
$internal-awsui-color-background-flash-error: #d13212 !default;
$internal-awsui-color-background-flash-warning: #0073bb !default;
$internal-awsui-color-background-flash-success: #1d8102 !default;
$internal-awsui-color-background-flash-info: #0073bb !default;
$internal-awsui-color-background-alert-error: #fdf3f1 !default;
$internal-awsui-color-background-alert-warning: #ffffff !default;
$internal-awsui-color-background-alert-success: #f2f8f0 !default;
$internal-awsui-color-background-alert-info: #f1faff !default;
$internal-awsui-color-background-progress-bar-layout-default: #eaeded !default;
$internal-awsui-color-background-progress-bar-layout-in-flash: rgba(255, 255, 255, 0.25) !default;
$internal-awsui-color-background-progress-bar-content-default: #0073bb !default;
$internal-awsui-color-background-progress-bar-content-in-flash: rgba(255, 255, 255, 0.7) !default;
$internal-awsui-color-background-calendar-today: #f2f3f3 !default;
$internal-awsui-color-background-container-header: #fafafa !default;
$internal-awsui-color-background-container-content: #ffffff !default;
$internal-awsui-color-background-control-default: #ffffff !default;
$internal-awsui-color-background-control-active: #0073bb !default;
$internal-awsui-color-background-control-selected: #f1faff !default;
$internal-awsui-color-background-control-hover: #f2f3f3 !default;
$internal-awsui-color-background-control-disabled: #eaeded !default;
$internal-awsui-color-background-radio-checked: #ffffff !default;
$internal-awsui-color-background-radio-disabled: #d5dbdb !default;
$internal-awsui-color-background-radio-checked-disabled: #ffffff !default;
$internal-awsui-color-background-tiles-disabled: #eaeded !default;
$internal-awsui-color-background-tooltip: #16191f !default;
$internal-awsui-color-background-token: #f1faff !default;
$internal-awsui-color-background-toggle-active: #99cbe4 !default;
$internal-awsui-color-background-toggle-handle: #ffffff !default;
$internal-awsui-color-background-button-normal-default: #ffffff !default;
$internal-awsui-color-background-button-normal-disabled: #ffffff !default;
$internal-awsui-color-background-button-normal-hover: #fafafa !default;
$internal-awsui-color-background-button-normal-active: #eaeded !default;
$internal-awsui-color-background-button-primary-default: #ec7211 !default;
$internal-awsui-color-background-button-primary-disabled: #ffffff !default;
$internal-awsui-color-background-button-primary-hover: #eb5f07 !default;
$internal-awsui-color-background-button-primary-active: #dd6b10 !default;
$internal-awsui-color-background-input: #ffffff !default;
$internal-awsui-color-background-dropdown-item-default: #ffffff !default;
$internal-awsui-color-background-dropdown-item-selected: #f1faff !default;
$internal-awsui-color-background-dropdown-item-highlighted: #f2f3f3 !default;
$internal-awsui-color-background-dropdown-item-filter-match-highlight: #f1faff !default;
$internal-awsui-color-background-badge-grey: #545b64 !default;
$internal-awsui-color-background-badge-green: #1d8102 !default;
$internal-awsui-color-background-badge-blue: #0073bb !default;
$internal-awsui-color-background-badge-red: #d13212 !default;
$internal-awsui-color-border-state-disabled: #d5dbdb !default;
$internal-awsui-color-border-alert-success: #1d8102 !default;
$internal-awsui-color-border-alert-error: #d13212 !default;
$internal-awsui-color-border-alert-warning: #aab7b8 !default;
$internal-awsui-color-border-alert-info: #0073bb !default;
$internal-awsui-color-border-primary-hover: #dd6b10 !default;
$internal-awsui-color-border-container-default: #eaeded !default;
$internal-awsui-color-border-container-top: #eaeded !default;
$internal-awsui-color-border-control-default: #aab7b8 !default;
$internal-awsui-color-border-control-active: #0073bb !default;
$internal-awsui-color-border-control-disabled: #eaeded !default;
$internal-awsui-color-border-control-hover: #879596 !default;
$internal-awsui-color-border-control-focused: #00a1c9 !default;
$internal-awsui-color-border-radio-default: #aab7b8 !default;
$internal-awsui-color-border-tiles-disabled: rgba(0, 0, 0, 0) !default;
$internal-awsui-color-border-tabs: #aab7b8 !default;
$internal-awsui-color-border-tooltip: #16191f !default;
$internal-awsui-color-border-token: #00a1c9 !default;
$internal-awsui-color-border-table-column-active: #879596 !default;
$internal-awsui-color-border-button-normal-default: #545b64 !default;
$internal-awsui-color-border-button-normal-hover: #16191f !default;
$internal-awsui-color-border-button-normal-active: #16191f !default;
$internal-awsui-color-border-button-normal-disabled: #d5dbdb !default;
$internal-awsui-color-border-button-primary-default: #ec7211 !default;
$internal-awsui-color-border-button-primary-hover: #dd6b10 !default;
$internal-awsui-color-border-button-primary-active: #ec7211 !default;
$internal-awsui-color-border-button-primary-disabled: #d5dbdb !default;
$internal-awsui-color-border-button-link-hover: #fafafa !default;
$internal-awsui-color-border-dropdown-item-default: #eaeded !default;
$internal-awsui-color-border-dropdown-item-selected: #00a1c9 !default;
$internal-awsui-color-border-dropdown-item-highlighted: #879596 !default;
$internal-awsui-color-border-dropdown-item-top: #eaeded !default;
$internal-awsui-color-border-dropdown-group: #eaeded !default;
$internal-awsui-color-border-divider: #eaeded !default;
$internal-awsui-color-border-column-separator: #d5dbdb !default;
$internal-awsui-color-border-layout: #d5dbdb !default;
$internal-awsui-color-shadow-default: rgba(0, 28, 36, 0.5) !default;
$internal-awsui-color-shadow-medium: rgba(0, 28, 36, 0.3) !default;
$internal-awsui-color-shadow-side: rgba(0, 28, 36, 0.15) !default;
$internal-awsui-color-shadow-focused-default: #00a1c9 !default;
$internal-awsui-color-shadow-focused-light: #00a1c9 !default;